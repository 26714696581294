<template>
  <div class="plk_layerlist">
    <Menu></Menu>
    <div class="viewer">
      <div class="list">
        <h5>{{$t('designer.cms.layers.listlayers.title')}}</h5>
        <!-- Folder -->
        <FolderList class="doce columns" @onChange="updateFolder"/>
        <div class="table-overflow">
          <table class="u-full-width">
            <thead>
            <tr>
              <th>{{$t('designer.cms.layers.listlayers.layername')}}</th>
              <th colspan="4"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, key) in files" :key="key">
              <td>
                {{file.resource.name}}
              </td>
              <td class="text-center">
                <switches @input="changeSwicth" :text-enabled="$t('designer.cms.layers.listlayers.use')" :text-disabled="$t('designer.cms.layers.listlayers.use')" v-model="file.resource.using" color="blue"></switches>
              </td>
              <td v-if="haveRelation(file.action, 'update')" @click="updateAbstraction(file.resource.idFolder, file.resource.id)" class="pointer">
                <i :title="$t('designer.cms.layers.listlayers.edit')" class="fas fa-edit"></i>
              </td>
              <td v-if="haveRelation(file.action, 'delete')" @click="executeAction(haveRelation(file.action, 'delete'), true, loadElementsLayer)" class="pointer">
                <i :title="$t('designer.cms.layers.listlayers.delete')" class="fas fa-trash"></i>
              </td>
              <td class="pointer" @click="downloadFile((file.link.find(link => link.params.rel === 'content') || {}).href, file.resource.name)">
                <i :title="$t('designer.cms.layers.listlayers.download')" class="fas fa-download"></i>
              </td>
            </tr>
            </tbody>
          </table>
          <button @click="createLayer()" class="w-100">{{$t('designer.cms.layers.listlayers.createlayer')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import FolderList from '../components/FolderList'
import Menu from '../../components/menus/Menu'
import mixincms from '@/mixins/cms'
import { VIEWS } from '@/router/design'
import Switches from 'vue-switches'
import { Store, DESIGN, USER } from '@/store'
import { APP_CONNECTION_CONFIG_FILE } from '@/enviroment/app'

export default {
  name: 'layerslist',
  components: {
    Menu,
    Switches,
    FolderList
  },
  mixins: [mixincms],
  data () {
    return {
      folderLayer: '',
      folders: [],
      files: [],
      connections: [],
      changedConnection: false
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadElementsLayer()
      this.loadFolders()
    },
    updateAbstraction (folder, abstraction) {
      this.$router.push({
        name: VIEWS.CMS.LAYER_UPDATE.name,
        query: { folder, abstraction }
      })
    },
    createLayer () {
      this.$router.push({
        name: VIEWS.CMS.LAYER_CREATOR.name,
        query: { folder: this.folderLayer }
      })
    },
    async loadElementsLayer () {
      this.connections = (await this.$servicesPLK.connection.getConnections(this.$servicesPLK.connection.TYPE.PLAKA)).map(c => (
        {
          id: c.id,
          type: c.type,
          url: c.url,
          folder: c.folder,
          abstraction: c.abstraction
        }
      ))
      const connectionsId = this.connections.map(c => c.abstraction)
      const files = await this.getElementsFile(this.folderLayer)
      this.files = files.map(f => {
        f.resource.using = connectionsId.includes(f.resource.id)
        return f
      })
    },
    async loadFolders () {
      this.folders = (await this.getFolders()).filter(e => this.haveRelation(e.action, 'create'))
    },
    changeSwicth () {
      Vue.set(this, 'changedConnection', true)
    },
    updateConnections (files) {
      files.forEach(async f => {
        const position = this.connections.findIndex(c => c.abstraction === f.resource.id)
        if (!f.resource.using && position !== -1) {
          this.connections = this.connections.filter(c => c.abstraction !== f.resource.id)
        } else if (f.resource.using && position === -1) {
          try {
            const statistics = await this.getStatistics(f.resource.idFolder, f.resource.id)
            statistics.forEach(statistic => {
              this.connections.push(
                {
                  id: `${this.folderLayer}/${f.resource.name}`,
                  type: 'plaka',
                  url: Store.state.cms.host,
                  folder: this.folderLayer,
                  abstraction: f.resource.id
                }
              )
            })
          } catch (e) {}
        }
      })
    },
    saveConnectionFile (content = '[]') {
      Store.commit({
        type: DESIGN.MUTATIONS.UPDATE_FILE_CONTENT,
        filename: APP_CONNECTION_CONFIG_FILE,
        content: {
          base64Data: btoa(content),
          data: content,
          isJSON: true
        }
      })
      if (content === '[]') {
        Store.commit(USER.MUTATIONS.REMOVE_ALL_USERS)
      }
    },
    updateFolder (folder) {
      this.folderLayer = folder
    }
  },
  watch: {
    folderLayer () {
      this.loadElementsLayer()
    },
    connections () {
      if (this.changedConnection) {
        this.saveConnectionFile(JSON.stringify(this.connections, null, 2))
        Vue.set(this, 'changedConnection', false)
      }
    },
    files: {
      deep: true,
      handler () {
        this.updateConnections(this.files)
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_layerlist {
    background: #f8f9fa;
    height: 100vh;

    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);

      table {
        input, textarea, select, fieldset {
          padding: .8rem;
          margin-top: 1rem;
          margin-bottom: 0px;
        }
      }

      .typeOption {
        min-width: 70px;
      }
    }
  }
</style>
